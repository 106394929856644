import { defineStore } from 'pinia'
import { ref } from 'vue'

import {
  AudienceFeedResource,
  CountryResource,
  DomainResource,
  MobileNetworkResource,
  OfferResource,
  SegmentResource,
  SmsCampaignResource,
  SmsRoutingRouteResource,
  SmsRouteCompanyResource,
} from '~/types/data-contracts'

export const useDictionariesStore = defineStore(
  'dictionary',
  () => {
    const campaigns = ref<SmsCampaignResource[]>([])
    const networks = ref<MobileNetworkResource[]>([])
    const countries = ref<CountryResource[]>([])
    const segments = ref<SegmentResource[]>([])
    const domains = ref<DomainResource[]>([])
    const offers = ref<OfferResource[]>([])
    const routes = ref<SmsRoutingRouteResource[]>([])
    const routingCompanies = ref<SmsRouteCompanyResource[]>([])
    const feeds = ref<AudienceFeedResource[]>([])

    return {
      campaigns,
      networks,
      countries,
      segments,
      domains,
      offers,
      routes,
      routingCompanies,
      feeds,
    }
  },
  {
    persist: {
      storage: persistedState.localStorage,
    },
  }
)
