// plugins/vue-showdown.js

import VueShowdown from 'vue-showdown'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(VueShowdown, {
    flavor: 'github',
    options: {
      emoji: true
    }
  })
})
