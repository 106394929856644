// import iframeResize from 'iframe-resizer/js/iframeResizer'

// eslint-disable-next-line no-undef
export default defineNuxtPlugin((NuxtApp) => {
    // NuxtApp.vueApp.use(iframeResize);

    // NuxtApp.vueApp.directive("resize", {
    //     mounted(el, binding) {
            // el.addEventListener("load", () => iframeResize(binding, el));
        // },
    // });
});
