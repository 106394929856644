import { default as _91_46_46_46slug_93ILszah2VNpMeta } from "/app/pages/[...slug].vue?macro=true";
import { default as settingsPd9ZMcsdolMeta } from "/app/pages/agents/agents/[id]/settings.vue?macro=true";
import { default as indexAn15p1wKG8Meta } from "/app/pages/agents/agents/index.vue?macro=true";
import { default as numbersqSzfpLYJUzMeta } from "/app/pages/agents/numbers.vue?macro=true";
import { default as settingsQpOOPfc9R3Meta } from "/app/pages/agents/settings.vue?macro=true";
import { default as tools4iwyAMZ6SYMeta } from "/app/pages/agents/tools.vue?macro=true";
import { default as indexUAqhzm8aZZMeta } from "/app/pages/ai-voice/agents/index.vue?macro=true";
import { default as _91_46_46_46contacts_93kl7rf9aFHNMeta } from "/app/pages/audience/contacts/[...contacts].vue?macro=true";
import { default as indexriocwwNAkoMeta } from "/app/pages/audience/feeds/index.vue?macro=true";
import { default as logs0HVh98Oot7Meta } from "/app/pages/audience/feeds/logs.vue?macro=true";
import { default as Create9M6WUBrvkfMeta } from "/app/pages/audience/upload/Create.vue?macro=true";
import { default as Uploads0f4l0eCUydMeta } from "/app/pages/audience/Uploads.vue?macro=true";
import { default as _91id_93rQaJ3bE3HbMeta } from "/app/pages/campaigns/[id].vue?macro=true";
import { default as indexJjfgnQLEBBMeta } from "/app/pages/campaigns/index.vue?macro=true";
import { default as index7FChqa4GMMMeta } from "/app/pages/campaigns/offers/index.vue?macro=true";
import { default as indexcV8OrzpfrzMeta } from "/app/pages/campaigns/reports/index.vue?macro=true";
import { default as forget_45passwordEVWK44q6hlMeta } from "/app/pages/forget-password.vue?macro=true";
import { default as _91_46_46_46any_93jiKOEMNqSfMeta } from "/app/pages/hub/companies/[...any].vue?macro=true";
import { default as email_45updatesRCj2rsp3OVMeta } from "/app/pages/hub/endpoints/[id]/email-updates.vue?macro=true";
import { default as indexc0KyjitWe7Meta } from "/app/pages/hub/endpoints/index.vue?macro=true";
import { default as _91_46_46_46any_93iFT8qJomNWMeta } from "/app/pages/hub/financials/[...any].vue?macro=true";
import { default as index1UpFPeINkbMeta } from "/app/pages/hub/index.vue?macro=true";
import { default as indexHrm7PGVYfTMeta } from "/app/pages/hub/routing/client/rates/index.vue?macro=true";
import { default as rulesCrR1GgzQFZMeta } from "/app/pages/hub/routing/client/rates/rules.vue?macro=true";
import { default as settingsLFv20mVYstMeta } from "/app/pages/hub/routing/client/settings.vue?macro=true";
import { default as ruleskN8HAQNyVgMeta } from "/app/pages/hub/routing/plans/[id]/rules.vue?macro=true";
import { default as indexYFhGnvMtrkMeta } from "/app/pages/hub/routing/plans/index.vue?macro=true";
import { default as routesuM5ozMKJQjMeta } from "/app/pages/hub/routing/routes.vue?macro=true";
import { default as _91_46_46_46id_93yDPCwtZzrfMeta } from "/app/pages/hub/routing/vendor/rates/[...id].vue?macro=true";
import { default as Changesh3BaIoRHIgMeta } from "/app/pages/hub/routing/vendor/rates/Changes.vue?macro=true";
import { default as InboxesKcv5JtKXQGMeta } from "/app/pages/hub/routing/vendor/rates/Inboxes.vue?macro=true";
import { default as settingsmjEZsVxWIgMeta } from "/app/pages/hub/routing/vendor/settings.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as billingslN3jt5tZwOMeta } from "/app/pages/integrations/[id]/billings.vue?macro=true";
import { default as indexCZc30927m5Meta } from "/app/pages/integrations/[id]/edit/index.vue?macro=true";
import { default as indexbV1NgSGxV8Meta } from "/app/pages/integrations/[id]/index.vue?macro=true";
import { default as requestsps9zN5vpVdMeta } from "/app/pages/integrations/[id]/requests.vue?macro=true";
import { default as createrRJmqXaY7DMeta } from "/app/pages/integrations/create.vue?macro=true";
import { default as indexwfmqj0GVbMMeta } from "/app/pages/integrations/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as _91_46_46_46my_45account_93yQXaZ3eC26Meta } from "/app/pages/my-account/[...my-account].vue?macro=true";
import { default as _91_46_46_46team_45settings_936sPIrZ1ZQqMeta } from "/app/pages/my-account/team-settings/[...team-settings].vue?macro=true";
import { default as notifications883AI3QFtaMeta } from "/app/pages/notifications.vue?macro=true";
import { default as _91_46_46_46any_93LO5WTpzlgMMeta } from "/app/pages/password-reset/[...any].vue?macro=true";
import { default as custom_45fieldsOASvIiabSsMeta } from "/app/pages/settings/custom-fields.vue?macro=true";
import { default as tokensmB8FRtVimXMeta } from "/app/pages/settings/tokens.vue?macro=true";
import { default as _91_46_46_46url_45shortener_93jp4VuTJTN2Meta } from "/app/pages/settings/url-shortener/[...url-shortener].vue?macro=true";
import { default as signupezBbZGXKU6Meta } from "/app/pages/signup.vue?macro=true";
import { default as rulesaL1yHaZe3FMeta } from "/app/pages/sms/routing/plans/[id]/rules.vue?macro=true";
import { default as indexcj2RWSiWA3Meta } from "/app/pages/sms/routing/plans/index.vue?macro=true";
import { default as _91_46_46_46id_93RfVMI0uO2UMeta } from "/app/pages/sms/routing/rates/[...id].vue?macro=true";
import { default as ChangesOzM5PdxNHbMeta } from "/app/pages/sms/routing/rates/Changes.vue?macro=true";
import { default as InboxesNnfIdwvEO6Meta } from "/app/pages/sms/routing/rates/Inboxes.vue?macro=true";
import { default as _91_46_46_46routes_93ZJ3pyyLdKfMeta } from "/app/pages/sms/routing/routes/[...routes].vue?macro=true";
import { default as _91_46_46_46any_93MoxFXUtOeVMeta } from "/app/pages/team-invitations/[...any].vue?macro=true";
import { default as verify_45emailHtn5VRk2WsMeta } from "/app/pages/verify-email.vue?macro=true";
import { default as voicea0iFxfMScqMeta } from "/app/pages/voice.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "agents-agents-id-settings",
    path: "/agents/agents/:id()/settings",
    component: () => import("/app/pages/agents/agents/[id]/settings.vue").then(m => m.default || m)
  },
  {
    name: "agents-agents",
    path: "/agents/agents",
    component: () => import("/app/pages/agents/agents/index.vue").then(m => m.default || m)
  },
  {
    name: "agents-numbers",
    path: "/agents/numbers",
    component: () => import("/app/pages/agents/numbers.vue").then(m => m.default || m)
  },
  {
    name: "agents-settings",
    path: "/agents/settings",
    component: () => import("/app/pages/agents/settings.vue").then(m => m.default || m)
  },
  {
    name: "agents-tools",
    path: "/agents/tools",
    component: () => import("/app/pages/agents/tools.vue").then(m => m.default || m)
  },
  {
    name: "ai-voice-agents",
    path: "/ai-voice/agents",
    component: () => import("/app/pages/ai-voice/agents/index.vue").then(m => m.default || m)
  },
  {
    name: "audience-contacts-contacts",
    path: "/audience/contacts/:contacts(.*)*",
    component: () => import("/app/pages/audience/contacts/[...contacts].vue").then(m => m.default || m)
  },
  {
    name: "audience-feeds",
    path: "/audience/feeds",
    component: () => import("/app/pages/audience/feeds/index.vue").then(m => m.default || m)
  },
  {
    name: "audience-feeds-logs",
    path: "/audience/feeds/logs",
    component: () => import("/app/pages/audience/feeds/logs.vue").then(m => m.default || m)
  },
  {
    name: "audience-upload-Create",
    path: "/audience/upload/Create",
    component: () => import("/app/pages/audience/upload/Create.vue").then(m => m.default || m)
  },
  {
    name: "audience-Uploads",
    path: "/audience/Uploads",
    component: () => import("/app/pages/audience/Uploads.vue").then(m => m.default || m)
  },
  {
    name: "campaigns-id",
    path: "/campaigns/:id()",
    component: () => import("/app/pages/campaigns/[id].vue").then(m => m.default || m)
  },
  {
    name: "campaigns",
    path: "/campaigns",
    component: () => import("/app/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: "campaigns-offers",
    path: "/campaigns/offers",
    component: () => import("/app/pages/campaigns/offers/index.vue").then(m => m.default || m)
  },
  {
    name: "campaigns-reports",
    path: "/campaigns/reports",
    component: () => import("/app/pages/campaigns/reports/index.vue").then(m => m.default || m)
  },
  {
    name: "forget-password",
    path: "/forget-password",
    meta: forget_45passwordEVWK44q6hlMeta || {},
    component: () => import("/app/pages/forget-password.vue").then(m => m.default || m)
  },
  {
    name: "hub-companies-any",
    path: "/hub/companies/:any(.*)*",
    component: () => import("/app/pages/hub/companies/[...any].vue").then(m => m.default || m)
  },
  {
    name: "hub-endpoints-id-email-updates",
    path: "/hub/endpoints/:id()/email-updates",
    component: () => import("/app/pages/hub/endpoints/[id]/email-updates.vue").then(m => m.default || m)
  },
  {
    name: "hub-endpoints",
    path: "/hub/endpoints",
    component: () => import("/app/pages/hub/endpoints/index.vue").then(m => m.default || m)
  },
  {
    name: "hub-financials-any",
    path: "/hub/financials/:any(.*)*",
    component: () => import("/app/pages/hub/financials/[...any].vue").then(m => m.default || m)
  },
  {
    name: "hub",
    path: "/hub",
    component: () => import("/app/pages/hub/index.vue").then(m => m.default || m)
  },
  {
    name: "hub-routing-client-rates",
    path: "/hub/routing/client/rates",
    component: () => import("/app/pages/hub/routing/client/rates/index.vue").then(m => m.default || m)
  },
  {
    name: "hub-routing-client-rates-rules",
    path: "/hub/routing/client/rates/rules",
    component: () => import("/app/pages/hub/routing/client/rates/rules.vue").then(m => m.default || m)
  },
  {
    name: "hub-routing-client-settings",
    path: "/hub/routing/client/settings",
    component: () => import("/app/pages/hub/routing/client/settings.vue").then(m => m.default || m)
  },
  {
    name: "hub-routing-plans-id-rules",
    path: "/hub/routing/plans/:id()/rules",
    component: () => import("/app/pages/hub/routing/plans/[id]/rules.vue").then(m => m.default || m)
  },
  {
    name: "hub-routing-plans",
    path: "/hub/routing/plans",
    component: () => import("/app/pages/hub/routing/plans/index.vue").then(m => m.default || m)
  },
  {
    name: "hub-routing-routes",
    path: "/hub/routing/routes",
    component: () => import("/app/pages/hub/routing/routes.vue").then(m => m.default || m)
  },
  {
    name: "hub-routing-vendor-rates-id",
    path: "/hub/routing/vendor/rates/:id(.*)*",
    component: () => import("/app/pages/hub/routing/vendor/rates/[...id].vue").then(m => m.default || m)
  },
  {
    name: "hub-routing-vendor-rates-Changes",
    path: "/hub/routing/vendor/rates/Changes",
    component: () => import("/app/pages/hub/routing/vendor/rates/Changes.vue").then(m => m.default || m)
  },
  {
    name: "hub-routing-vendor-rates-Inboxes",
    path: "/hub/routing/vendor/rates/Inboxes",
    component: () => import("/app/pages/hub/routing/vendor/rates/Inboxes.vue").then(m => m.default || m)
  },
  {
    name: "hub-routing-vendor-settings",
    path: "/hub/routing/vendor/settings",
    component: () => import("/app/pages/hub/routing/vendor/settings.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "integrations-id-billings",
    path: "/integrations/:id()/billings",
    component: () => import("/app/pages/integrations/[id]/billings.vue").then(m => m.default || m)
  },
  {
    name: "integrations-id-edit",
    path: "/integrations/:id()/edit",
    component: () => import("/app/pages/integrations/[id]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: "integrations-id",
    path: "/integrations/:id()",
    component: () => import("/app/pages/integrations/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "integrations-id-requests",
    path: "/integrations/:id()/requests",
    component: () => import("/app/pages/integrations/[id]/requests.vue").then(m => m.default || m)
  },
  {
    name: "integrations-create",
    path: "/integrations/create",
    component: () => import("/app/pages/integrations/create.vue").then(m => m.default || m)
  },
  {
    name: "integrations",
    path: "/integrations",
    component: () => import("/app/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginhHM0vSTW5jMeta || {},
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "my-account-myaccount",
    path: "/my-account/:myaccount(.*)*",
    component: () => import("/app/pages/my-account/[...my-account].vue").then(m => m.default || m)
  },
  {
    name: "my-account-team-settings-teamsettings",
    path: "/my-account/team-settings/:teamsettings(.*)*",
    component: () => import("/app/pages/my-account/team-settings/[...team-settings].vue").then(m => m.default || m)
  },
  {
    name: "notifications",
    path: "/notifications",
    component: () => import("/app/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-any",
    path: "/password-reset/:any(.*)*",
    meta: _91_46_46_46any_93LO5WTpzlgMMeta || {},
    component: () => import("/app/pages/password-reset/[...any].vue").then(m => m.default || m)
  },
  {
    name: "settings-custom-fields",
    path: "/settings/custom-fields",
    component: () => import("/app/pages/settings/custom-fields.vue").then(m => m.default || m)
  },
  {
    name: "settings-tokens",
    path: "/settings/tokens",
    component: () => import("/app/pages/settings/tokens.vue").then(m => m.default || m)
  },
  {
    name: "settings-url-shortener-urlshortener",
    path: "/settings/url-shortener/:urlshortener(.*)*",
    component: () => import("/app/pages/settings/url-shortener/[...url-shortener].vue").then(m => m.default || m)
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupezBbZGXKU6Meta || {},
    component: () => import("/app/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: "sms-routing-plans-id-rules",
    path: "/sms/routing/plans/:id()/rules",
    component: () => import("/app/pages/sms/routing/plans/[id]/rules.vue").then(m => m.default || m)
  },
  {
    name: "sms-routing-plans",
    path: "/sms/routing/plans",
    component: () => import("/app/pages/sms/routing/plans/index.vue").then(m => m.default || m)
  },
  {
    name: "sms-routing-rates-id",
    path: "/sms/routing/rates/:id(.*)*",
    component: () => import("/app/pages/sms/routing/rates/[...id].vue").then(m => m.default || m)
  },
  {
    name: "sms-routing-rates-Changes",
    path: "/sms/routing/rates/Changes",
    component: () => import("/app/pages/sms/routing/rates/Changes.vue").then(m => m.default || m)
  },
  {
    name: "sms-routing-rates-Inboxes",
    path: "/sms/routing/rates/Inboxes",
    component: () => import("/app/pages/sms/routing/rates/Inboxes.vue").then(m => m.default || m)
  },
  {
    name: "sms-routing-routes-routes",
    path: "/sms/routing/routes/:routes(.*)*",
    component: () => import("/app/pages/sms/routing/routes/[...routes].vue").then(m => m.default || m)
  },
  {
    name: "team-invitations-any",
    path: "/team-invitations/:any(.*)*",
    component: () => import("/app/pages/team-invitations/[...any].vue").then(m => m.default || m)
  },
  {
    name: "verify-email",
    path: "/verify-email",
    meta: verify_45emailHtn5VRk2WsMeta || {},
    component: () => import("/app/pages/verify-email.vue").then(m => m.default || m)
  },
  {
    name: "voice",
    path: "/voice",
    component: () => import("/app/pages/voice.vue").then(m => m.default || m)
  }
]